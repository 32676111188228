import apiClient from './api'

export default {
    async get (id) {
        return await apiClient.get(`/cms/companies/${id}`)
    },
    async getAll (params = null) {
        return await apiClient.get('/cms/companies', { params })
    },
    async paginate (params) {
        params.paginate = true
        params.perPage = params.itemsPerPage
        const result = await apiClient.get('/cms/companies', { params })
        result.data.total = Number(result.data.total)
        return result
    },
    async add (params) {
        return await apiClient.post('/cms/companies', params)
    },
    async edit (id, params) {
        return await apiClient.put(`/cms/companies/${id}`, params)
    },
    async delete (id) {
        return await apiClient.delete(`/cms/companies/${id}`)
    },
}